body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dark-mode {
    background-color: black;
}

.dark-mode h1 {
    color: white;
}

.container {
    max-width: 960px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    min-height: 100vh;
}

.notes-list {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.note.new {
    background-color: #67d7cc;
}

textarea {
    border: none;
    resize: none;
    background-color: #67d7cc;
}

textarea:focus {
    outline: none;
}

.save {
    background-color: #e1e1e1;
    border: none;
    border-radius: 15px;
    padding: 5px 10px 5px 10px;
}

.save:hover {
    background-color: #ededed;
    cursor: pointer;
}

.note {
    background-color: #fef68a;
    border-radius: 10px;
    padding: 1rem;
    min-height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    white-space: pre-wrap;
    /* keep the line breaks */
}

.note-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.delete-icon {
    cursor: pointer;
    font-size: 20px;
    transition: all 1s ease;
}

.delete-icon :hover {
    transform: translateY(-3px);
}

.search {
    display: flex;
    align-items: center;
    background-color: rgb(233, 233, 233);
    border-radius: 10px;
    padding: 5px;
    margin-bottom: 1.5em;
}

.search input {
    border: none;
    background-color: rgb(233, 233, 233);
}

.search input:focus {
    outline: none;
}